import React, { useState } from 'react';
import { User, LogOut, Trash2, Settings } from 'lucide-react';
import LoginModal from '../auth/LoginModal';
import DeleteAccountModal from '../auth/DeleteAccountModal';
import { useSupabase } from '../../contexts/SupabaseContext';
import { useNavigate } from 'react-router-dom';
import myImage from '../../assets/logo.png';

interface HeaderProps {
  userName?: string;
  hideUserControls?: boolean;
}

const Header: React.FC<HeaderProps> = ({ userName, hideUserControls = false }) => {
  const { user, signOut } = useSupabase();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleProfileClick = () => {
    if (user) {
      setShowDropdown(!showDropdown);
    } else {
      setIsLoginModalOpen(true);
    }
  };

  const handleLoginSuccess = () => {
    setIsLoginModalOpen(false);
    navigate('/profile');
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <header className="bg-transparent text-gray-100 p-4 relative">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-2 text-xl font-bold">
          {userName ? (
            <span className="text-blue-400 text-2xl">{userName}'s CV</span>
          ) : (
            <>
              <img src={myImage} alt="Chat My CV Logo" width="50" height="50" />
              <span className="text-blue-400 text-2xl">ChatMyCV</span>
            </>
          )}
        </div>
        {!hideUserControls && (
          <div className="relative">
            <button
              onClick={handleProfileClick}
              className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-full transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              <User size={24} />
            </button>

            {showDropdown && user && (
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1">
                  <button
                    onClick={() => {
                      setShowDropdown(false);
                      navigate('/profile');
                    }}
                    className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 flex items-center"
                  >
                    <Settings size={16} className="mr-2" />
                    Update Profile
                  </button>
                  <button
                    onClick={handleLogout}
                    className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 flex items-center"
                  >
                    <LogOut size={16} className="mr-2" />
                    Logout
                  </button>
                  <button
                    onClick={() => {
                      setShowDropdown(false);
                      setIsDeleteModalOpen(true);
                    }}
                    className="w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-gray-700 flex items-center"
                  >
                    <Trash2 size={16} className="mr-2" />
                    Delete Account
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLoginSuccess={handleLoginSuccess}
      />
      <DeleteAccountModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </header>
  );
};

export default Header;